import dayjs from 'dayjs';
import queryString from 'query-string';
import Cookies from 'js-cookie';
require('dayjs/locale/zh-hk');

const Common = {
  getLoginInfo(){
    const currentUserId = Cookies.get('userId');
    const token = Cookies.get('loginToken');
    const loginInfo = {
      userId: currentUserId,
      token,
    };
    return loginInfo;
  },
  filterItems(filter, items){
    const newItems = items.filter(function(item){
      for(let i = 0; i < Object.keys(filter).length; i++){
        const searchKey = Object.keys(filter)[i];
        const searchValue = filter[searchKey];
        if(searchValue.length !== 0 && isNaN(searchValue)){
          if(item[searchKey].toLowerCase().indexOf(searchValue.toLowerCase()) !== 0){
            return false;
          }
        }
        if(searchValue.length !== 0 && !isNaN(searchValue)){
          if(item[searchKey] != searchValue){
            return false;
          }
        }
      }
      return true;
    });
    return newItems;
  },
  findNonDuplicate(array1, array2){
    const nonDuplicateItems = array1.filter(function(val) {
      return array2.indexOf(val) == -1;
    });
    return nonDuplicateItems;
  },
  formatDateTime(timestamp, dateFormat, langcode){
    let date = dayjs.unix(timestamp);
    if(langcode){
      date = dayjs.unix(timestamp).locale(langcode);
    }
    date = date.format(dateFormat);
    return date;
  },
  //The function which will could accept the date format.
  getCurrentDate(dateFormat, langcode){
    let now = dayjs();
    if(langcode){
      now = dayjs().locale(langcode);
    }
    const currentDate = now.format(dateFormat);
    return currentDate;
  },
  async loadConfig(baseApiUrl, configName, loginInfo){
    return new Promise(async(resolve, reject) => {
      const queryList = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
      };

      const query = queryString.stringify(queryList);

      const apiUrl = `${baseApiUrl}/config/${configName}?${query}`;
      const fetchResult = await fetch(apiUrl, {
        method: 'GET',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot load system config. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();
      const configValue = resultJson["config_value"];

      resolve(configValue);
    });
  },
  async updateConfig(baseApiUrl, configName, configValue, loginInfo){
    return new Promise(async(resolve, reject) => {
      const putValues = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        value: configValue,
      };

      const apiUrl = `${baseApiUrl}/config/${configName}`;
      const fetchResult = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(putValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot update system config. Maybe there are some fields or network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  getCurrentTimestamp(){
    let now = dayjs();
    return now.unix();
  },
  createUUID(){
    const d = Date.now();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
      d += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  },
}

export default Common;
